<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        Order
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          v-if="$store.state.authUser.user_permissions.includes('add_order')"
          depressed
          @click="$router.push({path: '/order/create'})"
          color="success"
      >เปิดออเดอร์ใหม่
      </v-btn>
      <v-btn @click="showFilter = !showFilter" icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>
    <!--    <div class="mx-4 box box-2" style="min-height: 100%;">-->
    <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
    <!--        <v-col-->
    <!--            style="height: 100%"-->
    <!--        >-->
    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" style="overflow: auto" class="pl-4 pb-4">
          <v-expansion-panels>
            <v-expansion-panel
            >
              <v-expansion-panel-header>
                รายการใบเสนอราคาที่ยังไม่รับออเดอร์ ({{ quotationOrders.length }})
              </v-expansion-panel-header>
              <v-expansion-panel-content id="innerExPan" class="px-0">
                <v-data-table
                    loading-text="Loading... Please wait"
                    :custom-filter="customFilter"
                    :headers="headers"
                    :items="quotationOrders"
                    class="elevation-1"
                    no-data-text="ไม่มีข้อมูล"
                    :header-props="{ sortIcon: null }"
                    @click:row="gotoPODetail"
                    :search="search"

                >
                  <template v-slot:item.total_price="{item}">
                    <p class="text-right mb-0">{{ $currency(item.total_price).format() }}</p>
                  </template>

                  <template v-slot:item.order_item="{item}">
                    <p class="text-truncate" style="margin-bottom: 0">
                      {{ showProduct(item.order_item) }}
                    </p>
                  </template>

                  <template v-slot:item.order_status="{ item }">
                    <v-btn
                        :color="(colorStatus(item.order_status,item.isActive)).color"
                        outlined
                        raised
                        small
                        block
                    >{{ (colorStatus(item.order_status, item.isActive)).text }}
                    </v-btn>
                  </template>

                  <template v-slot:item.payment_status="{ item }">
                    <v-btn
                        :color="(colorStatus(item.payment_status,item.isActive)).color"
                        outlined
                        raised
                        small
                        block
                    >{{ (colorStatus(item.payment_status, item.isActive)).text }}
                    </v-btn>
                  </template>

                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card
              class="mt-4 rounded-lg elevation-2"
              outlined
              tile
          >

            <v-data-table
                :loading="$store.state.isLoading"
                loading-text="Loading... Please wait"
                :custom-filter="customFilter"
                :headers="headers"
                :items="orders"
                class="elevation-1"
                no-data-text="ไม่มีข้อมูล"
                :header-props="{ sortIcon: null }"
                @click:row="gotoPODetail"
                :search="search"


            >
              <template v-slot:item.total_price="{item}">
                <p class="text-right mb-0">{{ $currency(item.total_price).format() }}</p>
              </template>
              <template v-slot:item.id_display="{item}">
                {{ item.id_display }}
              </template>
              <template v-slot:item.order_item="{item}">
                <p class="text-truncate" style="margin-bottom: 0">
                  {{ showProduct(item.order_item) }}
                </p>

              </template>
              <template v-slot:item.order_status="{ item }">
                <!--                {{ item }}-->
                <v-btn
                    :color="(colorStatus(item.order_status,item.isActive)).color"
                    outlined
                    raised
                    small
                    block
                >{{ (colorStatus(item.order_status, item.isActive)).text }}
                </v-btn>
              </template>

              <template v-slot:item.payment_status="{ item }">
                <v-btn
                    :color="(colorStatus(item.payment_status,item.isActive)).color"
                    outlined
                    raised
                    small
                    block
                >{{ (colorStatus(item.payment_status, item.isActive)).text }}
                </v-btn>
              </template>
            </v-data-table>

          </v-card>
        </pane>

        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="loadProduct" :search-option="findHeaders"
                      date
                      tax-order
                      price-min-max
                      order-status
                      :backwards="0.7"
                      payment-status/>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DateTime from "luxon/src/datetime";

import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";
import _ from 'lodash';

export default {
  name: "POList",
  components: {FilterCard, Splitpanes, Pane},
  data() {
    return {
      orders: [],
      quotationOrders: [],
      showFilter: true,
      headers: [
        {text: 'เลขสั่งซื้อ', value: 'id_display', width: 80, align: 'center'},
        {text: 'ชื่อลูกค้า', value: 'customer_name', align: 'start', minWidth: 150,},
        {text: 'สินค้า', value: 'order_item'},
        {text: 'ราคา', value: 'total_price', align: 'center'},
        {text: 'วันที่สั่ง', value: 'order_date', align: 'center',},
        {text: 'หมายเหตุ', value: 'remark', width: 150,},
        {text: 'เลขกำกับภาษี', value: 'tax_invoice_id', align: 'center', width: 100},
        {text: 'สถานะ', value: 'order_status', align: 'center', width: 100},
        {text: 'ชำระเงิน', value: 'payment_status', align: 'center', width: 100},
      ],
      itemsPerPage: 0,
      footerProps: {'items-per-page-options': []},
      optionSearch: '',
      search: '',
      modal: false,
      dates: [],
      filteredPO: [],
      findHeaders: [
        {
          text: 'เลขคำสั่งซื้อ',
          value: 'display_id'
        },
        {
          text: 'ชื่อลูกค้า',
          value: 'customer_name'
        },
        {
          text: 'ชื่อสินค้า',
          value: 'product_name'
        },
      ]
    }
  },
  created() {
    // this.loadProduct()
    this.getQuotation()
  },
  methods: {
    loadProduct(params) {
      this.$store.state.isLoading = true
      axios.get('/api/orders/' + params)
          .then(res => {
            this.orders = res.data.data.filter(o => !(o.isActive === true && o.order_status === 'ใบเสนอราคา')) // NAND
            this.orders = _.orderBy(this.orders, ['id'], ['desc'])
          })
          .catch(e => {
            if (e.response.status === 401) {
              this.$router.push({path: '/login'})
            }
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
          .finally(() => {
            this.$store.state.isLoading = false
          })
    },
    getQuotation() {
      axios.get('/api/orders/?order_status=ใบเสนอราคา')
          .then(res => {
            this.quotationOrders = res.data.data.filter(o => o.isActive)
          })
          .catch(e => this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          })
          .finally(() => {
            // this.$store.state.isLoading = false
          })
    },
    showProduct(item) {
      let string = ''
      item.forEach((i, index) => {
        if (i.name !== 'ค่าส่ง') {
          string += (`${i.name} ${i.quantity} ${i.unit}${index !== item.length - 1 ? ', ' : ''}`)
        }
      })
      return string
    },
    gotoPODetail(row) {
      let routeData = this.$router.resolve({path: `/order/${row.id}`});
      window.open(routeData.href, '_blank');
    },
    inRangeItem(range, items, column) {
      if (range.length === 1) {
        this.filteredPO = items.filter(item => DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() === DateTime.fromFormat(range[0], 'yyyy-LL-dd').toISODate())
      } else {
        range.sort()
        this.filteredPO = items.filter(item =>
            DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() >= DateTime.fromFormat(range[0], 'yyyy-LL-dd').toISODate()
            && DateTime.fromFormat(item[column], 'yyyy/LL/dd').toISODate() <= DateTime.fromFormat(range[1], 'yyyy-LL-dd').toISODate())
      }
    },
    customFilter(value, search, item) {
      search = this.search.toString().toLowerCase()
      return item[this.optionSearch].toString().includes(search)
    },
    colorStatus(status, isActive) {
      if (isActive) {
        switch (status) {
          case 'รับออเดอร์':
            return {text: status, color: 'primary'}
          case 'ใบเสนอราคา':
            return {text: status, color: 'orange'}
          case 'ดำเนินการ':
            return {text: status, color: 'amber'}
          case 'สำเร็จ':
            return {text: status, color: 'green'}
          case 'ชำระแล้ว':
            return {text: status, color: 'green'}
          case 'ยังไม่ชำระ':
            return {text: status, color: 'error'}
        }
      } else {
        if (status === 'ใบเสนอราคา') {
          return {text: 'ยกเลิกใบเสนอราคา', color: 'red'}
        } else {
          return {text: 'ยกเลิกออเดอร์', color: 'red'}
        }
      }
    },
    yearBeforeOrder(date) {
      return (parseInt(date.split('/')[0]) + 543) % 100
    }
    // formatDate(second) {
    //   return DateTime.fromSeconds(second).toFormat('dd/LL/yyyy')
    // },
  },
  computed: {
    dateRangeText() {
      if (this.dates === null) {
        return ""
      } else {
        // this.dates.sort()
        return this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort().join(' - ')
      }
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>